import {
  DELETED_STUDENTS,
  GAPYEAR_STUDENTS,
  INACTIVE_STUDENT,
  STUDENTS,
  STUDENT_INFO,
  STUDENT_INFO_PUBLIC,
  WITHDRAWAL_STUDENTS,
} from '@constants/routes'
import {
  ACCOUNTING_HEAD,
  ADMIN,
  AMO_CRM_OPERATOR,
  ARCHIVIST,
  COURSE_LEADER,
  DEAN,
  EXAM_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  STUDENTS_DEPARTMENT,
  STUDENT_VIEWER,
  SUPER_ADMIN,
  SYSTEM_MODERATOR,
} from '@constants/userRoles'
import { lazy } from 'react'

export const StudentRoutes = [
  {
    path: STUDENTS,
    component: lazy(() => import('../views/Students')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        DEAN,
        COURSE_LEADER,
        ADMIN,
        STUDENTS_DEPARTMENT,
        SYSTEM_MODERATOR,
        ACCOUNTING_HEAD,
        AMO_CRM_OPERATOR,
        STUDENT_VIEWER,
        ARCHIVIST,
        EXAM_HEAD,
      ],
    },
  },
  {
    path: STUDENT_INFO,
    component: lazy(() => import('../views/StudentFullProfile/StudentFullProfile')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        DEAN,
        COURSE_LEADER,
        ADMIN,
        STUDENTS_DEPARTMENT,
        SYSTEM_MODERATOR,
        ACCOUNTING_HEAD,
        AMO_CRM_OPERATOR,
        STUDENT_VIEWER,
        ARCHIVIST,
        EXAM_HEAD,
      ],
    },
  },
  {
    path: STUDENT_INFO_PUBLIC,
    component: lazy(() => import('../views/StudentPublicProfile')),
    exact: true,
    meta: {
      layout: 'blank',
      isPublic: true,
    },
  },
  {
    path: WITHDRAWAL_STUDENTS,
    component: lazy(() => import('../views/InactiveStudents')),
    exact: true,
    meta: {
      status: 'WITHDRAWAL',
      access: [SUPER_ADMIN, ADMIN, ACCOUNTING_HEAD, EXAM_HEAD],
    },
  },
  {
    path: GAPYEAR_STUDENTS,
    component: lazy(() => import('../views/InactiveStudents')),
    exact: true,
    meta: {
      status: 'ACADEMIC_LEAVE',
      access: [SUPER_ADMIN, ADMIN, ACCOUNTING_HEAD, EXAM_HEAD],
    },
  },
  {
    path: INACTIVE_STUDENT,
    component: lazy(() => import('../views/InactiveStudents')),
    exact: true,
    meta: {
      status: 'INACTIVE',
      access: [SUPER_ADMIN, ADMIN, ACCOUNTING_HEAD, EXAM_HEAD],
    },
  },
  {
    path: DELETED_STUDENTS,
    component: lazy(() => import('../views/InactiveStudents')),
    exact: true,
    meta: {
      status: 'DELETED',
      access: [SUPER_ADMIN, ADMIN, ACCOUNTING_HEAD, EXAM_HEAD],
    },
  },
]
