import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const student: ApiFunc<`${typeof API.STUDENT}{id}`, 'get'> = data =>
  instance.get(`${API.STUDENT}${'id' in data ? data.id : ''}`, {
    params: data,
  })

export const studentList: ApiFunc<`${typeof API.STUDENT}`, 'get'> = (data, config = {}) => {
  return instance.get(API.STUDENT, {
    params: data,
    ...config,
  })
}

export const studentDelete: ApiFunc<`${typeof API.STUDENT}{ids}`, 'delete'> = data =>
  instance.delete(`${API.STUDENT}${'ids' in data ? data.ids : ''}`)

export const studentProfile: ApiFunc<`${typeof API.STUDENT_PROFILE}`, 'get'> = data =>
  instance.get(API.STUDENT_PROFILE, {
    params: data,
  })

export const studentProfileUpdate: ApiFunc<`${typeof API.STUDENT_PROFILE}`, 'put'> = (data, config) =>
  instance.put(API.STUDENT_PROFILE, data, config)

export const studentAchievementRecords: ApiFunc<`/${typeof API.ACHIEVEMENT_RECORDS_STUDENT}{id}`, 'get'> = data =>
  instance.get(`${API.STUDENT_ACHIEVEMENT_RECORDS}${'id' in data ? data.id : ''}`, {
    params: data,
  })

export const studentAll: ApiFunc<`${typeof API.STUDENT_ALL}`, 'get'> = data =>
  instance.get(API.STUDENT_ALL, {
    params: data,
  })

export const studentGuard: ApiFunc<`${typeof API.STUDENT_GUARD}`, 'get'> = data =>
  instance.get(API.STUDENT_GUARD, {
    params: data,
  })

export const studentSendSms: ApiFunc<`${typeof API.STUDENT_SEND_SMS}`, 'post'> = data =>
  instance.post(API.STUDENT_SEND_SMS, data)

export const studentExportData: ApiFunc<`${typeof API.STUDENT_EXPORT_DATA}`, 'post'> = data =>
  instance.post(API.STUDENT_EXPORT_DATA, data)

export const studentTransfer: ApiFunc<`${typeof API.STUDENT_TRANSFER}`, 'post'> = data =>
  instance.post(API.STUDENT_TRANSFER, data)

export const studentGuardCreate: ApiFunc<`${typeof API.STUDENT_GUARD}`, 'post'> = data =>
  instance.post(API.STUDENT_GUARD, data)

export const studentDocumentCreate: ApiFunc<`${typeof API.STUDENT_DOCUMENT}`, 'post'> = data =>
  instance.post(API.STUDENT_DOCUMENT, data)

export const studentUsername: ApiFunc<`${typeof API.STUDENT_USERNAME}{username}`, 'get'> = data =>
  instance.get(`${API.STUDENT_USERNAME}${'username' in data ? data.username : ''}`, {
    params: data,
  })

export const generateTranscript: ApiFunc<`${typeof API.GENERATE_TRANSCRIPT}`, 'post'> = data =>
  instance.post(API.GENERATE_TRANSCRIPT, data)

export const studentDebitDetailCreate: ApiFunc<`${typeof API.STUDENT_DEBIT_DETAIL}`, 'post'> = data =>
  instance.post(API.STUDENT_DEBIT_DETAIL, data)

export const studentResumeStudy: ApiFunc<`${typeof API.STUDENT_RESUME_STUDY}`, 'post'> = data =>
  instance.post(API.STUDENT_RESUME_STUDY, data)

export const studentDebitDetailDelete: ApiFunc<`${typeof API.STUDENT_DEBIT_DETAIL}{id}`, 'delete'> = data =>
  instance.delete(`${API.STUDENT_DEBIT_DETAIL}${'id' in data ? data.id : ''}`)

export const studentPersonalInfo: ApiFunc<`${typeof API.STUDENT_PERSONAL_INFO}`, 'put'> = data =>
  instance.put(API.STUDENT_PERSONAL_INFO, data)

export const studentContactInfo: ApiFunc<`${typeof API.STUDENT_CONTACT_INFO}`, 'put'> = data =>
  instance.put(API.STUDENT_CONTACT_INFO, data)

export const updateStudentStatus: ApiFunc<`${typeof API.STUDENT_STATUS}`, 'put'> = data =>
  instance.put(API.STUDENT_STATUS, data)

export const studentCallLetterDelete: ApiFunc<`${typeof API.STUDENT_CALL_LETTER_DELETE}{id}`, 'delete'> = data =>
  instance.delete(`${API.STUDENT_CALL_LETTER_DELETE}${'id' in data ? data.id : ''}`)

export const studentMarkUpdate: ApiFunc<`${typeof API.STUDENT_MARK_UPDATE}`, 'put'> = data =>
  instance.put(API.STUDENT_MARK_UPDATE, data)

export const studentInvoiceCreate: ApiFunc<`${typeof API.STUDENT_INVOICE_CREATE}`, 'put'> = data =>
  instance.put(API.STUDENT_INVOICE_CREATE, data)

export const studentUpdatePassport: ApiFunc<`${typeof API.STUDENT_UPDATE_PASSPORT}`, 'put'> = data =>
  instance.put(API.STUDENT_UPDATE_PASSPORT, data)
